*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
}

body {
  font-family: system-ui;
}

:root {
  --header-height: 2.5rem;
  --nav-height: 2.25rem;
  --body-height: calc(100% - var(--header-height) - var(--nav-height));
}

header {
  position: absolute;
  top: 0;
  z-index: 100;
  padding: 0.5rem 1rem;
  margin: 0;
  width: 100%;
  height: var(--header-height);
  background-color: aliceblue;
  display: flex;
  align-items: center;
}

nav {
  padding: calc(var(--header-height) + 0.5rem) 1rem 0.5rem;
  height: calc(var(--header-height) + var(--nav-height));
}

nav a {
  margin-right: 0.5ch;
}

nav a.current-page {
  text-decoration: none;
  color: inherit;
}

main {
  padding: 1rem;
  height: var(--body-height);
  width: 100%;
  background-color: aliceblue;
}

header img {
  margin-right: 0.25rem;
}

h1,
p,
li {
  margin-block-start: 0;
  margin-block-end: 0.5rem;
}

web-vitals {
  position: absolute;
  z-index: 200;
  top: 1rem;
  right: 1rem;
  width: 24ch;
}
